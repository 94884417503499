import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import 'bootstrap'
import routes from '@/routes'
import App from '@/App'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faHotel,
  faPersonWalking,
  faPhone,
  faPlaneArrival,
  faPlaneDeparture,
  faShop,
  faStore,
  faTrainSubway,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faHotel,
  faPersonWalking,
  faPhone,
  faPlaneArrival,
  faPlaneDeparture,
  faShop,
  faStore,
  faTrainSubway,
  faUtensils,
)

createApp(App)
  .use(createRouter({
    history: createWebHistory(),
    routes,
  }))
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
