<template>
  <div class="list">
    <div class="list-title">{{ $route.params.date }}</div>
    <div v-for="(el, index) in timeline" :key="index" class="list-item">
      <time>{{ el.date }} {{ el.time }}</time>
      <SchedulePlace v-if="el.place" :place="places[el.place]" />
      <ScheduleRouteList v-if="el.routes" :routes="el.routes" />
    </div>
  </div>
</template>

<script>
import SchedulePlace from '@/components/SchedulePlace'
import ScheduleRouteList from '@/components/ScheduleRouteList'

export default {
  name: 'ScheduleList',
  components: { ScheduleRouteList, SchedulePlace },
  props: ['places', 'timeline'],
}
</script>

<style scoped>

</style>
