<template>
404
</template>

<script>
export default {
  name: 'NotFoundPage',
}
</script>

<style scoped>

</style>
