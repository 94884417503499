<template>
  <div v-if="routes" class="route-list">
    <ScheduleRoute v-for="(route, index) in routes" :key="index" :route="route" />
  </div>
</template>

<script>
import ScheduleRoute from '@/components/ScheduleRoute'

export default {
  name: 'ScheduleRouteList',
  components: { ScheduleRoute },
  props: ['routes'],
}
</script>

<style scoped>

</style>
