<template>
  <div class="list-item route-item small">
    <time v-if="route.time" class="route-time my-1">{{ route.time }}</time>
    <div class="d-flex my-1">
      <font-awesome-icon v-if="route.icon" :icon="route.icon" class="route-icon" />
      <div class="d-flex flex-grow-1 flex-column">
        <div v-if="route.from" class="route-from mx-2">出發地：{{ route.from }}</div>
        <div v-if="route.to" class="route-to mx-2">目的地：{{ route.to }}</div>
      </div>
    </div>
    <div v-if="route.lines" class="route-line-list my-1">
      <div
          v-for="(line, index) in route.lines"
          :key="index"
          :style="route.color ? ColorUtil.setContrast(route.color) : {}"
          class="route-line d-inline-block mx-1 px-1"
      >{{ line }}
      </div>
    </div>
  </div>
</template>

<script>
import ColorUtil from '@/utils/ColorUtil'

export default {
  name: 'ScheduleRoute',
  props: ['route'],
  data() {
    return {
      ColorUtil,
    }
  },
}
</script>

<style scoped>

</style>
