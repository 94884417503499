const Schedules = {
  '2023-01-10 ~ 2023-01-14': {
    places: {
      kia: {
        name: {
          en: 'Kansai International Airport',
          jp: '関西国際空港',
          tw: '關西國際機場',
        },
        address: {
          en: '1 Senshukukokita, Izumisano-shi, Osaka',
          jp: '大阪府泉佐野市泉州空港北1',
          zipcode: '549-0001',
        },
        url: 'https://www.kansai-airport.or.jp/',
      },
      'mop-osaka-tsurumi': {
        name: {
          en: 'MITSUI OUTLET PARK Osaka Tsurumi',
          jp: '三井アウトレットパーク大阪鶴見',
          tw: '三井 OUTLET PARK 大阪鶴見',
        },
        address: {
          en: '2 Chome-7-70 Mattaomiya, Tsurumi Ward, Osaka',
          jp: '大阪府大阪市鶴見区茨田大宮２丁目７−70',
          zipcode: '538-0031',
        },
        url: 'https://mitsui-shopping-park.com/mop/osaka/',
      },
      'hotel-amaterrace': {
        name: {
          en: ' Hotel Amaterrace Yosuga',
          jp: 'ホテル アマテラス 縁 -よすが-',
          tw: '緣天照露台飯店',
        },
        address: {
          en: '5 Chome-21-4 Nipponbashi, Naniwa Ward, Osaka',
          jp: '大阪府大阪市浪速区日本橋５丁目２１−4',
          zipcode: '556-0005',
        },
        tel: '06-7501-8533',
        url: 'https://hotel-amaterrace.com/',
      },
      'temma': {
        name: {
          en: 'Temma Station',
          jp: '天満駅',
          tw: '天滿站',
        },
      },
      'donki-osaka-temma': {
        name: {
          en: 'Don Quijote Osaka Temma Station',
          jp: 'ドン・キホーテ 天満駅店',
          tw: '驚安殿堂唐吉訶德天滿站店',
        },
        address: {
          en: '1-42 Nishikicho, Kita Ward, Osaka',
          jp: '大阪府大阪市北区錦町１−42',
          zipcode: '530-0034',
        },
        url: 'https://www.donki.com/store/shop_detail.php?shop_id=609',
      },
      'ichiran-dotonbori-south': {
        name: {
          en: 'Ichiran Dotonbori Store Annex',
          jp: '一蘭 道頓堀店別館',
          tw: '一蘭 道頓堀店別館',
        },
        url: 'https://ichiran.com/shop/kinki/dotonbori-south/',
      },
      'kuromon': {
        name: {
          en: 'Kuromon Market',
          jp: '黒門市場',
          tw: '黑門市場',
        },
        address: {
          en: '2 Chome Nipponbashi, Chuo Ward, Osaka',
          jp: '大阪府大阪市中央区日本橋２丁目',
          zipcode: '542-0073',
        },
        url: 'https://kuromon.com/',
      },
    },
    timeline: [
      {
        date: '2023-01-10',
        time: '10:25',
        place: 'kia',
        icon: 'fa-solid fa-plane-arrival',
      },
      {
        date: '2023-01-10',
        time: '11:10~12:39',
        routes: [
          {
            time: '11:10~11:55',
            from: '關西機場',
            to: '南海難波',
            lines: ['南海機場線', '南海本線'],
            icon: 'fa-solid fa-train-subway',
            color: '#228b22',
          },
          {
            time: '12:02~12:03',
            from: '難波站',
            to: '心齋橋站',
            lines: ['御堂筋線'],
            icon: 'fa-solid fa-train-subway',
            color: '#dd2f18',
          },
          {
            time: '12:08~12:33',
            from: '心齋橋站',
            to: '門真南',
            lines: ['長堀鶴見綠地線'],
            icon: 'fa-solid fa-train-subway',
            color: '#afd331',
          },
        ],
      },
      {
        date: '2023-01-10',
        time: '12:30-17:30',
        place: 'mop-osaka-tsurumi',
        icon: 'fa-solid fa-shop',
      },
      {
        date: '2023-01-10',
        time: '17:33~18:13',
        routes: [
          {
            time: '11:10~11:55',
            from: '門真南',
            to: '長堀橋',
            lines: ['長堀鶴見綠地線'],
            icon: 'fa-solid fa-train-subway',
            color: '#afd331',
          },
          {
            time: '11:10~11:55',
            from: '長堀橋',
            to: '惠美須町',
            lines: ['堺筋線'],
            icon: 'fa-solid fa-train-subway',
            color: '#ad4a28',
          },
        ],
      },
      {
        date: '2023-01-10',
        time: '18:15-18:30',
        place: 'hotel-amaterrace',
        icon: 'fa-solid fa-hotel',
      },
      {
        date: '2023-01-10',
        time: '18:30~18:48',
        routes: [
          {
            time: '18:34~18:45',
            from: '惠美須町',
            to: '扇町（大阪）',
            lines: ['堺筋線'],
            icon: 'fa-solid fa-train-subway',
            color: '#ad4a28',
          },
        ],
      },
      {
        date: '2023-01-10',
        time: '18:48-22:00',
        place: 'temma',
        icon: 'fa-solid fa-store',
      },
      {
        date: '2023-01-10',
        time: '22:00-22:30',
        place: 'donki-osaka-temma',
        icon: 'fa-solid fa-shop',
      },
      {
        date: '2023-01-10',
        time: '23:00',
        place: 'ichiran-dotonbori-south',
        icon: 'fa-solid fa-utensils',
      },
      {
        date: '2023-01-10',
        time: '24:00-08:00',
        place: 'hotel-amaterrace',
        icon: 'fa-solid fa-hotel',
      },
      {
        date: '2023-01-11',
        time: '08:00-08:20',
        routes: [
          {
            time: '08:00-08:20',
            from: 'ホテル アマテラス 縁 -よすが-',
            to: '黒門市場',
            icon: 'fa-solid fa-person-walking',
          },
        ],
        routeUrl: 'https://goo.gl/maps/DwjZ8DZ8Zpi9uUpH9',
      },
      {
        date: '2023-01-11',
        time: '08:20-11:00',
        place: 'kuromon',
        icon: 'fa-solid fa-store',
      },
    ],
  },
}

export default Schedules
