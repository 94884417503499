const ColorUtil = {
  rgbToHex(r, g, b) {
    return '#' + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)
  },
  hexToRgb(hex) {
    const regex = hex.length < 6
      ? /^#?([a-f\d])([a-f\d])([a-f\d])$/i
      : /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
    const result = regex.exec(hex)
    if (!result) return null
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
  },
  setContrast(backgroundColor) {
    const rgb = ColorUtil.hexToRgb(backgroundColor)
    const brightness = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000)
    const color = brightness > 125 ? 'black' : 'white'
    return {
      backgroundColor,
      color,
    }
  },
}

export default ColorUtil
