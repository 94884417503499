<template>
  <ScheduleList :places="schedule.places" :timeline="schedule.timeline" />
</template>

<script>
import Schedules from '@/schedules'
import ColorUtil from '@/utils/ColorUtil'
import ScheduleList from '@/components/ScheduleList'

export default {
  name: 'SchedulePage',
  components: { ScheduleList },
  data() {
    return {
      ColorUtil,
      schedule: Schedules[this.$route.params.date],
    }
  },
}
</script>

<style scoped>

</style>
