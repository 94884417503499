import HomePage from '@/pages/HomePage'
import NotFoundPage from '@/pages/NotFoundPage'
import SchedulePage from '@/pages/SchedulePage'

const routes = [
  { path: '/', name: 'Home', component: HomePage },
  { path: '/schedule/:date', name: 'Schedule', component: SchedulePage },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundPage },
]

export default routes
