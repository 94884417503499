<template>
  <div class="list">
    <router-link
        v-for="date in dates"
        :key="date"
        :to="{ name: 'Schedule', params: { date }}"
        class="list-item"
    >{{ date }}
    </router-link>
  </div>
</template>

<script>
import Schedules from '@/schedules'

export default {
  name: 'HomePage',
  data() {
    return {
      dates: Object.keys(Schedules),
    }
  },
  computed: {},
  methods: {},
}
</script>

<style scoped>

</style>
