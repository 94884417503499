<template>
  <div class="place">
    <div v-if="place.name" class="place-name">
      <div lang="ja">{{ place.name.jp }}</div>
      <div lang="zh">{{ place.name.tw }}</div>
      <div lang="en">{{ place.name.en }}</div>
    </div>
    <div v-if="place.address" class="place-address">
      <div class="zipcode">〒{{ place.address.zipcode }}</div>
      <div lang="ja">{{ place.address.jp }}</div>
      <div lang="en">{{ place.address.en }}</div>
    </div>
    <div v-if="place.tel" class="place-tel">
      <a :href="'tel:' + place.tel" :title="place.tel">
        <font-awesome-icon icon="fa-solid fa-phone" />
        {{ place.tel }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchedulePlace',
  props: ['place'],
}
</script>

<style scoped>

</style>
