<template>
  <header>
    <router-link :to="{ name: 'Home' }" class="nav-top">
      <img alt="Osaka Logo" :src="`${baseUrl}osaka-castle.png`" />
      Japan 2023
    </router-link>
  </header>
  <main>
    <router-view></router-view>
  </main>
  <footer>&copy; 2022-2023</footer>
</template>

<script>
export default {
  name: 'App',
  data() {
    return { baseUrl: process.env.BASE_URL }
  },
}
</script>

<style>
@import "bootstrap";

.nav-top {
  display: block;
  font-size: large;
  padding: .5rem;
  text-align: center;
  text-decoration: none;
}

.nav-top img {
  vertical-align: middle;
  width: 20px;
}

.list-item {
  border: 1px solid;
  display: block;
  margin: .5rem;
  padding: .5rem;
}

footer {
  text-align: center;
}

* {
  background-color: whitesmoke;
  color: black;
}

a, a:link, a:visited, a:focus, a:hover, a:active {
  color: steelblue;
}

a:focus, a:hover, a:active {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
}

@media (prefers-color-scheme: dark) {
  * {
    background-color: darkslategray;
    color: white;
  }

  a, a:link, a:visited, a:focus, a:hover, a:active {
    color: lightsteelblue;
  }

}
</style>
